.section2-container {
  display: flex;
  flex-direction: column; /* Align children in a row */
  align-items: center; /* Center children vertically */
  justify-content: center; /* Center children horizontally */
  font-family: "KoHo", sans-serif; /* Use KoHo font */
  height: 100vh;
  width: 100vw;
  position: relative;
  
}

.section2-background {
  background-image: url("../assets/images/section2bg3.png");
  background-position: top; /* Align the top of the image with the top of the container */
  background-repeat: no-repeat; /* Stop the background from repeating */
  background-size: cover; /* Ensures the background covers the entire element */
}

.section2-container h2 {
  font-size: 6em; /* Example headline font size */
  margin-bottom: 35px;
  font-family: "CCAskForMercy";
  text-align: center;
}

.section2-container h2 span {
  font-family: "CCAskForMercy";
}

.sec2maintext {
  font-size: 3em; /* Increased font size for the text */
  max-width: 85%;
  font-family: "CCAskForMercy";
  text-align: center;
  background-color: #f7f2d7; /* Pale yellow */
  border: 2px solid #c5a365; /* Added a solid border */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  border-radius: 10px;
  padding: 20px; /* Add padding around the text */
}
.red-highlight {
  color: #da291c; /* McDonald's red */
}

.yellow-highlight {
  color: #f9d423; /* Rich golden yellow */
}

.kim-rocket {
  position: absolute;
  top: 1%;
  left: 1%;
  width: 20%;
  height: auto;
  animation: float 3s ease-in-out infinite;
}

.wecan-image {
  position: absolute;
  top: 7%;
  align-items: center;
  width: 6%;
  height: auto;
}

.wemust-image {
  position: absolute;
  bottom: 5%;
  right: 15%;
  width: 7%;
  height: auto;
}

.please-image {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 6%;
  height: auto;
}

.redsplatter-1 {
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 15%;
  height: auto;
}

.ThisIsIt {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 7%;
}

/* For devices with a width of 480px or less (like portrait phones) */
@media only screen and (max-width: 480px) {
  

  .section2-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .section2-container h2 {
    font-size: 2.0em; /* Further reduced for very small screens */
  }

  .section2-container p {
    font-size: 1.25em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .kim-rocket {
    position: static; /* Remove absolute positioning */
    margin-bottom: 20px; /* Add some margin below the rocket */
    width: 60%;
    height: auto;
  }

  .wecan-image {
    position: absolute;
    left: 5%;
    top: 5%;

    width: 10%;
    height: auto;
  }

  .wemust-image {
  display: none;
  }

  .please-image {
    display: none;
  }

  .redsplatter-1 {
    position: absolute;
    bottom: 50%;
    left: 16%;
    width: 75%;
    height: auto;
    z-index: -1;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {

  .section2-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .section2-container h2 {
    font-size: 2.75em; /* Further reduced for very small screens */
  }

  .section2-container p {
    font-size: 1.5em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .kim-rocket {
    position: static; /* Remove absolute positioning */
    margin-bottom: 20px; /* Add some margin below the rocket */
    width: 60%;
    height: auto;
  }

  .wecan-image {
    position: absolute;
    left: 10%;
    top: 10%;

    width: 15%;
    height: auto;
  }

  .wemust-image {
  display: none;
  }

  .please-image {
    position: absolute;
    top: 15%;
    right: 10%;
    width: 10%;
    height: auto;
  }

  .redsplatter-1 {
    position: absolute;
    bottom: 50%;
    left: 16%;
    width: 75%;
    height: auto;
    z-index: -1;
  }

}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
 

  .section2-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .section2-container h2 {
    font-size: 2.75em; /* Further reduced for very small screens */
  }

  .section2-container p {
    font-size: 2.25em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .kim-rocket {
    position: static; /* Remove absolute positioning */
    margin-bottom: 20px; /* Add some margin below the rocket */
    width: 60%;
    height: auto;
  }

  .wecan-image {
    position: absolute;
    left: 10%;
    top: 10%;

    width: 15%;
    height: auto;
  }

  .wemust-image {
  display: none;
  }

  .please-image {
    position: absolute;
    top: 15%;
    right: 10%;
    width: 10%;
    height: auto;
  }

  .redsplatter-1 {
    position: absolute;
    bottom: 50%;
    left: 16%;
    width: 75%;
    height: auto;
    z-index: -1;
  }
}

@media only screen and (min-width: 2560px) {
  .section2-container p {
    font-size: 3.75em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }
}
