html,
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.scroll-section-outer {
  overflow: hidden;
}

.scroll-section-inner {
  height: 100vh;
  width: 400vw;
  display: flex;
  flex-direction: row;
  position: relative;
}

.scroll-section {
  height: 100vh;
  width: 100vw;
  display: flex;
}
