.section1-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  position: relative;
  font-family: "KoHo", sans-serif;
  height: 100vh;
  width: 100vw;
}

.comment-container {
  position: absolute;
  opacity: 0; /* Start with invisible images */
  transition: opacity 0.3s ease-in-out; /* Faster transition */
}

.sec1-video {
  width: 20%; /* Adjusts the size of the video */
  height: 60%; /* Maintains the aspect ratio */
  background-color: transparent; /* Ensure the background is transparent */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #c5a365; /* Added a solid border */
  z-index: 100; /* Keeps the video behind other elements if needed */
  box-shadow: 0 0 8px rgba(255, 233, 234, 0.7),
              0 0 16px rgba(202, 202, 202, 0.5), 
              0 0 24px rgba(155, 155, 155, 0.3),
              0 0 32px rgba(255, 255, 255, 0.5); /* Intensified neon shadow */
  transition: transform 0.3s ease-in-out; /* Smooth hover effect */
}

.sec1-video:hover {
  transform: scale(1.10); /* Slight enlargement on hover */
}


.sec1-video:hover {
  transform: scale(1.10); /* Slightly enlarges the video on hover */
}

.sec1-text {
  background-color: #f7f2d7; /* Pale yellow */
  padding: 20px; /* Add padding around the text */
  color: #000000; /* Dark grey text for contrast */
  font-size: 2.25em;
  max-width: 50%;
  font-weight: 700;
  font-family: "CCAskForMercy";
  text-align: center;
  border-radius: 10px;
  border: 1px solid #c5a365; /* Added a solid border */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  margin-top: 5vh;
}

.yes-sec1 {
  position: absolute;
  left: 2%;
  top: 2%;

  width: 4%;
  height: auto;
}

.not-joke {
  position: absolute;
  left: 3%;
  top: 23%;

  width: 20%;
  height: auto;
}

.no-seriously {
  position: absolute;
  left: 8%;
  top: 28%;

  width: 10%;
  height: auto;
}

.visible {
  opacity: 1;
  transition: opacity 0.1s ease-in; /* Faster fade in */
}

.comment1-container {
  /* This will ensure the image is at the top and centered, but no longer affects the vertical centering of the content row */

  top: 1vh;
  left: 1vw;
  position: absolute; /* Changed to absolute */
}

.comment2-container {
  /* This will ensure the image is at the top and centered, but no longer affects the vertical centering of the content row */
  top: 4vh;
  left: 1vw;
  position: absolute; /* Changed to absolute */
}

.comment3-container {
  /* This will ensure the image is at the top and centered, but no longer affects the vertical centering of the content row */
  top: 1vh;
  left: 1vw;
  position: absolute; /* Changed to absolute */
}

.comment4-container {
  /* This will ensure the image is at the top and centered, but no longer affects the vertical centering of the content row */
  top: 8vh;
  right: 6vw;
  position: absolute; /* Changed to absolute */
}

.comment5-container {
  top: 10vh;
  left: 3vw;
  position: absolute;
}

.comment6-container {
  top: 12vh;
  right: 5vw;
  position: absolute;
}

.comment7-container {
  top: 20vh;
  left: 1vw;
  position: absolute;
}

.comment8-container {
  top: 7vh;
  right: 7vw;
  position: absolute;
}

.comment9-container {
  top: 20vh;
  left: 1vw;
  position: absolute;
}

.comment10-container {
  top: 8vh;
  right: 8vw;
  position: absolute;
}

.comment11-container {
  top: 20vh;
  left: 1vw;
  position: absolute;
}

.comment12-container {
  top: 5vh;
  right: 5vw;
  position: absolute;
}

.comment1-image,
.comment4-image,
.comment5-image,
.comment6-image,
.comment7-image,
.comment8-image,
.comment9-image,
.comment10-image,
.comment11-image,
.comment12-image {
  width: 125%; /* Adjust as needed */
  height: auto; /* Keeps the aspect ratio */
}

.comment2-image,
.comment3-image {
  width: 110%; /* Adjust as needed */
  height: auto; /* Keeps the aspect ratio */
}



.section1-image {
  max-width: 22%;

  height: auto;
  animation: float 3s ease-in-out infinite;
}

.gws-smiley {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 15%;
  height: auto;
}

.drawn-smiley {
  position: absolute;
  top: 20%;
  right: 8%;
  width: 7%;
  height: auto;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* For devices with a width of 480px or less (like portrait phones) */
@media only screen and (max-width: 480px) {
  .section1-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .gws-smiley {
    display: none;
  }

  .section1-container {
    margin-bottom: 10vh;
  }
  .sec1-video {
    width: 75%; /* Makes the video smaller */
    height: 60%; /* Keeps the aspect ratio */
    border-radius: 10px; /* Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visual effect */
    transition: transform 0.3s ease-in-out; /* Smooth hover effect */
  }


  .section1-image {
    max-width: 50%;

    height: auto;
  }
  .section1-container p {
    font-size: 1.5em; /* Smallest font size for mobile phones */
    max-width: 90%;
  }

  .comment1-image,
  .comment2-image,
  .comment3-image,
  .comment4-image,
  .comment5-image,
  .comment6-image,
  .comment7-image,
  .comment8-image,
  .comment9-image,
  .comment10-image,
  .comment11-image,
  .comment12-image,
  .comment1-container,
  .comment2-container,
  .comment3-container,
  .comment4-container,
  .comment5-container,
  .comment6-container,
  .comment7-container,
  .comment8-container,
  .comment9-container,
  .comment10-container,
  .comment11-container,
  .comment12-container {
    display: none; /* This will make them invisible on the page */
  }

  .yes-sec1 {
    display:none;
  }

  .not-joke {
    display:none;
  }
  
  .no-seriously {
    display:none;
  }

  /* You may also want to adjust layout, hide elements, etc., for mobile */
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section1-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .gws-smiley {
    display: none;
  }

  .section1-container {
    margin-bottom: 10vh;
  }
  .sec1-video {
    width: 55%; /* Makes the video smaller */
    height: 40%; /* Keeps the aspect ratio */
    border-radius: 10px; /* Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visual effect */
    transition: transform 0.3s ease-in-out; /* Smooth hover effect */
  }
  .section1-image {
    max-width: 50%;

    height: auto;
  }
  .section1-container p {
    font-size: 2.0em; /* Smallest font size for mobile phones */
    max-width: 90%;
  }

  .comment1-image,
  .comment2-image,
  .comment3-image,
  .comment4-image,
  .comment5-image,
  .comment6-image,
  .comment7-image,
  .comment8-image,
  .comment9-image,
  .comment10-image,
  .comment11-image,
  .comment12-image,
  .comment1-container,
  .comment2-container,
  .comment3-container,
  .comment4-container,
  .comment5-container,
  .comment6-container,
  .comment7-container,
  .comment8-container,
  .comment9-container,
  .comment10-container,
  .comment11-container,
  .comment12-container {
    display: none; /* This will make them invisible on the page */
  }

  /* You may also want to adjust layout, hide elements, etc., for mobile */

  .yes-sec1 {
    display:none;
  }

  .not-joke {
    display:none;
  }
  
  .no-seriously {
    display:none;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .section1-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
 
  .gws-smiley {
    display: none;
  }

  .section1-container {
    margin-bottom: 10vh;
  }
  .sec1-video {
    width: 45%; /* Makes the video smaller */
    height: 30%; /* Keeps the aspect ratio */
    border-radius: 10px; /* Adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visual effect */
    transition: transform 0.3s ease-in-out; /* Smooth hover effect */
  }
  .section1-image {
    max-width: 50%;

    height: auto;
  }
  .section1-container p {
    font-size: 2.5em; /* Smallest font size for mobile phones */
    max-width: 90%;
  }

  .comment1-image,
  .comment2-image,
  .comment3-image,
  .comment4-image,
  .comment5-image,
  .comment6-image,
  .comment7-image,
  .comment8-image,
  .comment9-image,
  .comment10-image,
  .comment11-image,
  .comment12-image,
  .comment1-container,
  .comment2-container,
  .comment3-container,
  .comment4-container,
  .comment5-container,
  .comment6-container,
  .comment7-container,
  .comment8-container,
  .comment9-container,
  .comment10-container,
  .comment11-container,
  .comment12-container {
    display: none; /* This will make them invisible on the page */
  }

  /* You may also want to adjust layout, hide elements, etc., for mobile */

}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {

  .section1-container p {
    font-size: 1.75em; /* Smallest font size for mobile phones */
    max-width: 60%;
  }
  
}

@media only screen and (min-width: 1281px) and (max-width: 1800px) {

  .section1-container p {
    font-size: 1.75em; /* Smallest font size for mobile phones */
    max-width: 60%;
  }
}

@media only screen and (min-width: 2560px) {
  .section1-container p {
    font-size: 3.5em; /* Smallest font size for mobile phones */
    max-width: 90%;
  }
}
