@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.hero-section {
  position: relative;
  height: 100vh;
  color: #000;

  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 10; /* This should be lower than the modal's z-index */

}

.hero-image {
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 31%;
  height: auto;
  animation: comeIntoScreen 4s ease-in-out infinite;
}

.gws-image {
  position: absolute;
  bottom: 40%;
  left: 30%;
  width: 8%;
  height: auto;
}

.grimes-sig {
  position: absolute;
  top: 5%;
  left: 15%;
  width: 6%;
  height: auto;
}

.gws-smiley-hero {
  position: absolute;
  bottom: 40%;
  right: 30%;
  width: 5%;
  height: auto;
}

.kim-image {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 15%;
  height: auto;
}

.trump-image {
  position: absolute;
  bottom: 0%;
  right: 39%;
  width: 20%;
  height: auto;
}

.shewas-image {
  position: absolute;
  top: 1%;
  left: 15%; /* Center horizontally relative to its parent */

  width: 4%;
  height: auto;
}

.worker-image {
  position: absolute;
  bottom: 30%;
  right: 10%; /* Center horizontally relative to its parent */

  width: 14%;
  height: auto;
}

.falc-image {
  position: absolute;
  top: 0%;
  left: 0%; /* Center horizontally relative to its parent */

  width: 10%;
  height: auto;
}

.marx-image {
  position: absolute;
  bottom: 35%;
  right: 10%; /* Center horizontally relative to its parent */

  width: 18%;
  height: auto;
}

.ubi-image {
  position: absolute;
  bottom: 7%;
  right: 22%; /* Center horizontally relative to its parent */

  width: 10%;
  height: auto;
}

.yes-image {
  display: none;
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes comeIntoScreen {
  0%,
  100% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.02) translateY(-15px);
  }
}

.einstein-image {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 8%;
  height: auto;
  animation: wiggle 0.6s ease-in-out infinite;
}

.hero-title {
  font-family: "Bebas Neue", sans-serif;
  padding-top: 10vh;
  font-size: 9em;
  margin: 0;
  z-index: 1000;
  display: inline-block; /* Ensures block-level styling and text alignment */
  width: 100%; /* Ensure full width */
  text-align: center; /* Center align the text */
}
.hero-title span {
  font-family: inherit; /* Ensure font family is inherited */
  font-size: inherit; /* Ensure font size is inherited */
  display: inline-block; /* Allows margin to be applied */
  margin: 0 10px; /* Adjusts spacing on the left and right */
}

.yellow {
  color: #ffc72c; /* McDonald's yellow */
}

.black {
  color: #000000; /* Black */
}

.red {
  color: #da291c; /* McDonald's red */
}

.red,
.yellow {
  text-shadow: -1.5px -1.5px 0 #000, 1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000,
    1.5px 1.5px 0 #000, -1.5px 0 0 #000, 1.5px 0 0 #000, 0 -1.5px 0 #000,
    0 1.5px 0 #000;
}

.black {
  color: #000000; /* Black */
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    2px 2px 0 #fff;
}
.hero-subtitle {
  font-size: 1.5em;
  z-index: 1001;
  font-family: "CCAskForMercy";
}

@media only screen and (max-width: 480px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Ensure text alignment is centered */
    padding: 1vh 0; /* Adjust padding to ensure proper spacing */
    height: auto; /* Adjust height to fit content */
    margin-bottom: 10vh;
  }

  .ubi-image {
    display: none;
  }

  .yes-image {
    display: none;
  }

  .einstein-image {
    display: none;
  }

  .hero-title {
    font-size: 5.5em; /* Adjusted font size for mobile screens */
    padding-top: 5vh;
  }

  .hero-subtitle {
    font-size: 1.3em; /* Adjusted font size for readability on mobile */
    margin-bottom: 10vh;
  }

  .hero-image {
    position: static; /* Default positioning */
    width: 85%; /* Adjust width for mobile viewing */

    margin-right: 5vw;
  }

  .gws-image {
    display: none;
  }

  .grimes-sig {
    display: none;
  }

  /* Hide other images for a cleaner layout */

  .trump-image,
  .falc-image,
  .kim-image,
  .worker-image,
  .shewas-image,
  .gws-smiley-hero {
    display: none;
  }
}

/* SMALL TABLETS */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Ensure text alignment is centered */
    padding: 5vh 0; /* Adjust padding to ensure proper spacing */
    height: auto; /* Adjust height to fit content */
  }

  .ubi-image {
    display: none;
  }

  .yes-image {
    display: none;
  }

  .einstein-image {
    display: none;
  }

  .hero-title {
    font-size: 6em; /* Adjusted font size for small tablets */
  }

  .hero-subtitle {
    font-size: 1.5em; /* Adjusted font size for readability on small tablets */
    margin-bottom: 5vh;
  }

  .hero-image {
    position: static; /* Default positioning */
    width: 70%; /* Adjust width for small tablets */
    margin-right: 5vw;
  }

  .gws-image {
    display: none;
  }

  /* Hide other images for a cleaner layout */
  .trump-image,
  .falc-image,
  .kim-image,
  .worker-image,
  .shewas-image {
    display: none;
  }
}

/* BIG TABLETS */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Ensure text alignment is centered */
    padding: 5vh 0; /* Adjust padding to ensure proper spacing */
    height: auto; /* Adjust height to fit content */
  }

  .ubi-image {
    position: absolute;
    left: 2%;
    top: 2%; /* Adjust position as needed */
    width: 15%;
    height: auto;
  }

  .yes-image {
    position: absolute;
    right: 5%;
    bottom: 20%; /* Adjust position as needed */
    width: 15%;
    height: auto;
    display: block; /* Ensure it displays */
  }

  .einstein-image {
    position: absolute;
    right: 2%;
    top: 2%; /* Adjust position as needed */
    width: 15%;
    height: auto;
  }

  .hero-title {
    font-size: 9em; /* Adjusted font size for small tablets */
  }

  .hero-subtitle {
    font-size: 2.5em; /* Adjusted font size for readability on small tablets */
    margin-bottom: 8vh;
  }

  .hero-image {
    position: static; /* Default positioning */
    width: 60%; /* Adjust width for big tablets */
    margin-right: 5vw;
  }

  .gws-image {
    position: absolute;
    left: 5%;
    bottom: 40%; /* Adjust position as needed */
    width: 18%;
    height: auto;
  }

  /* Hide other images for a cleaner layout */
  .trump-image,
  .falc-image,
  .kim-image,
  .worker-image,
  .shewas-image {
    display: none;
  }
}

/* Small Screens */
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .hero-image {
    width: 40%;
  }

  .gws-image {
    left: 35%;
  }

  .kim-image {
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 20%;
    height: auto;
  }

  .trump-image {
    position: absolute;
    bottom: 2%;
    right: 32%;
    width: 25%;
    height: auto;
  }

  .worker-image {
    position: absolute;
    bottom: 30%;
    right: 2%; /* Center horizontally relative to its parent */

    width: 15%;
    height: auto;
  }

  .ubi-image {
    position: absolute;
    bottom: 30%;
    right: 20%; /* Center horizontally relative to its parent */

    width: 13%;
    height: auto;
  }
}

/* Wide screens (1440p resolution and above) */
@media only screen and (min-width: 2560px) {
  .hero-title {
    font-size: 13em;
  }

  .hero-subtitle {
    font-size: 2.25em;
  }
}
