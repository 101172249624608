.section4-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  position: relative;
  font-family: "KoHo", sans-serif;
  height: 100vh;
  width: 100vw;
}

.section4-background {
  background-image: url("../assets/images/section4bg.png");
  background-position: top; /* Align the top of the image with the top of the container */
  background-repeat: no-repeat; /* Stop the background from repeating */
  background-size: cover;
}

.section4-container h2 {
  font-size: 4.5em; /* Example headline font size */
  text-align: center;
  margin-bottom: 30px;
  font-family: "CCAskForMercy";
}

.section4-container p {
  color: #000000; /* Paragraph text color */
  font-size: 2.5em; /* Increased font size for the text */
  max-width: 85%;
  font-family: "CCAskForMercy";
  text-align: center;

  background-color: #f7f2d7; /* Pale yellow */
  border: 2px solid #c5a365; /* Added a solid border */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  border-radius: 10px;
  padding: 20px; /* Add padding around the text */
}

.section4-image {
  width: 40%; /* Adjust as needed */
  height: auto;

  margin-bottom: 5vh;
}

.peace-sign2 {
  position: absolute;
  top: 0%;
  right: 2%;
  width: 15%;
  height: auto;
}

.ok-emoji {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 18%;
  height: auto;
  animation: float 3s ease-in-out infinite;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease; /* Adjust the duration (0.5s) as needed */
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease;
}

@media only screen and (max-width: 480px) {
  .section4-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section4-container h2 {
    font-size: 2.0em; /* Further reduced for very small screens */
    max-width: 80%;
    text-align: center;
  }

  .section4-container p {
    font-size: 1.25em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section4-image {
    width: 90%; /* Adjust as needed */
    height: auto;

    margin-bottom: 5vh;
  }

  .ok-emoji {
    display: none;
  }

  .peace-sign2 {
    display: none;
  }
}


@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section4-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section4-container h2 {
    font-size: 2.5em; /* Further reduced for very small screens */
    max-width: 80%;
    text-align: center;
  }

  .section4-container p {
    font-size: 2.0em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section4-image {
    width: 90%; /* Adjust as needed */
    height: auto;

    margin-bottom: 5vh;
  }

  .ok-emoji {
    display: none;
  }

  .peace-sign2 {
    display: none;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .section4-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section4-container h2 {
    font-size: 2.75em; /* Further reduced for very small screens */
    max-width: 80%;
    text-align: center;
  }

  .section4-container p {
    font-size: 2.25em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section4-image {
    width: 90%; /* Adjust as needed */
    height: auto;

    margin-bottom: 5vh;
  }

  .ok-emoji {
    display: none;
  }

  .peace-sign2 {
    display: none;
  }
}

@media only screen and (min-width: 2560px) {
  .section4-container p {
    font-size: 3.5em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }
}
