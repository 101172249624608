@font-face {
  font-family: "Machin";
  src: local("Machin"), url("../fonts/Machin-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SoupBone";
  src: local("Soupbone"),
    url("../fonts/SoupBone\ Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CoverUp";
  src: local("CoverUp"), url("../fonts/CoverUp-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CoverUp Italic";
  src: local("CoverUp"), url("../fonts/CoverUp-Italic.otf") format("opentype");
}

@font-face {
    font-family: 'CCAskForMercy';
    src: local('CCAskForMercy'), url('../fonts/CCAskForMercy-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'AllThingsPink';
    src: local('AllThingsPink'), url('../fonts/All\ Things\ Pink.ttf') format('truetype');
  }