.section3-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  position: relative;
  font-family: "KoHo", sans-serif;
  height: 100vh;
  width: 100vw;
 
}

.section3-image {
  animation: float 2s ease-in-out infinite;
  width: 22%; /* Smaller image width, adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 3vh; /* Space between the image and the text */
}

.section3-background {
  background-image: url("../assets/images/section3bg.png");
  background-position: top; /* Align the top of the image with the top of the container */
  background-repeat: no-repeat; /* Stop the background from repeating */
  background-size: cover; /* Ensures the background covers the entire element */
  background-color: white;
}

.section3-container h2 {
  font-size: 4.25em; /* Example headline font size */
  font-family: "CCAskForMercy";
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}

.main-text {
  color: #000000; /* Paragraph text color */
  font-size: 2.25em; /* Increased font size for the text */
  max-width: 80%;
  font-family: "CCAskForMercy";
  font-weight: 500;
  text-align: center;
  background-color: #f7f2d7; /* Pale yellow */
  border: 2px solid #c5a365; /* Added a solid border */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  border-radius: 10px;
  padding: 20px; /* Add padding around the text */
}

.dad-image {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 25%;
  height: auto;
}



.collective-farm {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 10%;
  height: auto;
}

.peace-sign {
  position: absolute;
  top: 25%;
  left: 18%;
  width: 8%;
  height: auto;
}

.long-swr {
  position: absolute;
  top: 4%;
  right: 4%;
  width: 10%;
  height: auto;
}

.solution-pic {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 20%;
  height: auto;
}

.yes-image-sec3 {
  position: absolute;
  top: 40%;
  left: 8%;
  width: 4%;
  height: auto;
  
}


@keyframes electrocute {
  0%,
  100% {
    filter: brightness(100%) contrast(100%);
    transform: translate(0, 0);
  }
  5% {
    filter: brightness(210%) contrast(210%);
    transform: translate(0.2vw, -0.2vh); /* Less movement */
  }
  10% {
    filter: brightness(60%) contrast(250%);
    transform: translate(-0.2vw, 0.2vh); /* Less movement */
  }
  15% {
    filter: brightness(160%) contrast(160%);
    transform: translate(0.1vw, -0.1vh); /* Less movement */
  }
  20% {
    filter: brightness(60%) contrast(200%);
    transform: translate(-0.1vw, 0.1vh); /* Less movement */
  }
  25% {
    filter: brightness(210%) contrast(110%);
    transform: translate(0.2vw, -0.2vh); /* Less movement */
  }
  30% {
    filter: brightness(60%) contrast(180%);
    transform: translate(-0.2vw, 0.2vh); /* Less movement */
  }
  35% {
    filter: brightness(180%) contrast(180%);
    transform: translate(0.2vw, -0.2vh); /* Less movement */
  }
  40%,
  60% {
    filter: brightness(110%) contrast(250%);
    transform: translate(-0.2vw, 0.2vh); /* Less movement */
  }
  45%,
  55% {
    filter: brightness(230%) contrast(110%);
    transform: translate(0.2vw, -0.2vh); /* Less movement */
  }
  50% {
    filter: brightness(60%) contrast(260%);
    transform: translate(-0.2vw, 0.2vh); /* Less movement */
  }
  65% {
    filter: brightness(180%) contrast(160%);
    transform: translate(0.1vw, -0.1vh); /* Less movement */
  }
  70% {
    filter: brightness(60%) contrast(230%);
    transform: translate(-0.1vw, 0.1vh); /* Less movement */
  }
  75% {
    filter: brightness(210%) contrast(130%);
    transform: translate(0.2vw, -0.2vh); /* Less movement */
  }
  80%,
  90% {
    filter: brightness(80%) contrast(310%);
    transform: translate(-0.1vw, 0.1vh); /* Less movement */
  }
  85% {
    filter: brightness(180%) contrast(180%);
    transform: translate(0.1vw, -0.1vh); /* Less movement */
  }
}

.electrocuted {
  animation: electrocute 0.025s infinite; /* Increased speed */
}


@media only screen and (max-width: 480px) {
  .section3-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section3-container h2 {
    font-size: 2.0em; /* Further reduced for very small screens */
  }

  .section3-container p {
    font-size: 1.2em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section3-image {
    width: 65%; /* Smaller image width, adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 3vh; /* Space between the image and the text */
  }

  .long-swr {
    position: absolute;
    top: 15%;
    right: 5%;
    width: 20%;
    height: auto;
  }

  .dad-image {
    display: none;
  }
  .solution-pic {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section3-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section3-container h2 {
    font-size: 2.5em; /* Further reduced for very small screens */
  }

  .section3-container p {
    font-size: 2.0em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section3-image {
    width: 65%; /* Smaller image width, adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 3vh; /* Space between the image and the text */
  }

  .long-swr {
    position: absolute;
    top: 15%;
    right: 8%;
    width: 22%;
    height: auto;
  }

  .dad-image {
    display: none;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .section3-container {
    height: auto; /* Use auto height */
    width: 100%; /* Use full width of the viewport */
    margin-bottom: 15vh;
  }
  .section3-container h2 {
    font-size: 2.5em; /* Further reduced for very small screens */
  }

  .section3-container p {
    font-size: 2.0em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }

  .section3-image {
    width: 65%; /* Smaller image width, adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 3vh; /* Space between the image and the text */
  }

  .long-swr {
    position: absolute;
    top: 15%;
    right: 5%;
    width: 22%;
    height: auto;
  }

  .dad-image {
    display: none;
  }
}

@media only screen and (min-width: 2560px) {
  .section3-container p {
    font-size: 3.5em; /* Further reduced for very small screens */
    max-width: 90%; /* Increase max-width to accommodate narrower screens */
  }
}
