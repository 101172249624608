.home-container {
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/background.png');
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeInAnimation {
  animation: fadeIn 0.5s ease-in-out;
}