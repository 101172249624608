.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Optional: light background for contrast */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px; /* Increased size for better visibility */
  height: 50px;
  border-radius: 50%;
  border-left-color: darkorange; /* Change this to a more orange tone */
  border-top-color: red; /* Red for the top border */
  border-right-color: darkyellow; /* Dark yellow for the right border */
  border-bottom-color: orange; /* Orange for the bottom border */
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
