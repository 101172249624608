.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  margin-bottom: 10vh;
  position: relative;
}

.video-section-player {
  width: 85vw;
  height: 85vh;
  transition: transform 0.3s ease; /* Smooth transitions for effects */
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  border: 2px solid #c5a365; /* Added a solid border */
  z-index: 10; /* Lower z-index means it will be behind elements with a higher z-index */
  box-shadow: 0 0 8px rgba(255, 233, 234, 0.7),
    0 0 16px rgba(202, 202, 202, 0.5), 0 0 24px rgba(155, 155, 155, 0.3),
    0 0 32px rgba(255, 255, 255, 0.5); /* Intensify neon shadow with multiple layers */
}

.video-section-player:hover {
  transform: scale(1.01); /* Slightly increase size on hover */
}

.video-container {
  position: relative;
}

.BackArrow,
.ForwardArrow {
  position: absolute;
  top: 43%;
  align-items: center;
  cursor: pointer;
  z-index: 20; /* Ensure it's on top of the video player */
  transition: opacity 0.5s ease, transform 0.3s ease; /* Smooth transitions */
  opacity: 0; /* Initially hidden */
}

.BackArrow {
  left: 3%; /* Center vertically, 5% from the left */
}

.ForwardArrow {
  right: 3%; /* Center vertically, 5% from the right */
}

.BackArrow svg,
.ForwardArrow svg {
  width: 5vw;
  height: auto;
  fill: #ece7e7; /* White fill */
  border-radius: 50%; /* Make the background circular */
  padding: 10px; /* Add some padding for better visuals */
  transition: transform 0.3s ease-in-out;
}

.ForwardArrow:hover svg,
.BackArrow:hover svg {
  transform: scale(1.1); /* Slightly increase size */
  fill: #ece7e7; /* White fill */
}

.ForwardArrow:active svg,
.BackArrow:active svg {
  transform: scale(1.05); /* Slightly decrease size to simulate a press */
}

.ForwardArrow:hover,
.BackArrow:hover {
  transform: scale(1.1); /* Slightly increase size */
  /* Optionally add a shadow or change color */
}

.ForwardArrow:active,
.BackArrow:active {
  transform: scale(0.95); /* Slightly decrease size to simulate a press */
}

.video-section-image {
  width: 15%;
  height: auto;
  margin-bottom: 2vh;
}

.slogan-image {
  position: absolute;
  top: 10%;
  right: 20%;
  width: 15%;
  height: auto;
}

.freepalestine-img {
  position: absolute;
  top: 3%;
  left: 5%;
  width: 20%;
  height: auto;
}

.fist-img {
  position: absolute;
  top: 2%;
  left: 4%;
  width: 14%;
  height: auto;
}

.shewas-video {
  position: absolute;
  top: 5%;
  right: 25%;
  width: 8%;
  height: auto;
}

.smiley-img {
  position: absolute;
  top: 4%;
  right: 10%;
  width: 11%;
  height: auto;
}

.redsplatter-2 {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 18%;
  height: auto;
}

.yellowsplatter {
  position: absolute;
  top: 32%;
  left: 0%;
  width: 20%;
  height: auto;
  z-index: 0; /* Lower z-index means it will be behind elements with a higher z-index */
}

.shewas-2 {
  position: absolute;
  bottom: 5%;
  left: 2%;
  width: 4%;
  height: auto;
}

.video-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-text {
  margin-top: 8px; /* Space between the icon and text */
  font-size: 1.5em; /* Set font size */
  color: #000000; /* Set text color, choose what fits your design */
  font-family: "CCAskForMercy";
}

@media only screen and (max-width: 480px) {
  .video-section {
    margin-bottom: 10vh;
  }

  .BackArrow svg,
  .ForwardArrow svg {
    width: 15vw;
    height: auto;
    fill: #ece7e7; /* White fill */
    border-radius: 50%; /* Make the background circular */
    padding: 10px; /* Add some padding for better visuals */
    transition: fill 0.2s, transform 0.2s ease-in-out;
  }

  .video-section-image {
    width: 50%; /* Adjust the width for smaller screens */
    margin-bottom: 0vh; /* Adjust the margin as needed */
  }

  .slogan-image,
  .freepalestine-img,
  .fist-img,
  .smiley-img,
  .redsplatter-2,
  .yellowsplatter,
  .shewas-2 {
    display: none;
  }

  .video-section-player {
    width: 95vw; /* Make the video player take more width */
    height: 70vh;
    margin-bottom: 5vh;
  }

  .video-navigation {
    flex-direction: column; /* Stack arrows vertically */
    margin-top: 3vh; /* Add some margin at the top */
  }

  .arrow-text {
    font-size: 1em; /* Reduce font size for smaller screens */
  }
}
