.section5-container {
  position: relative; /* Ensure your container has relative positioning */
  display: flex;
  flex-direction: column; /* Align children in a row */
  align-items: center; /* Center children vertically */
  justify-content: center; /* Center children horizontally */



  margin-bottom: 15vh;
}

.section5-image {
  max-width: 25%; /* Smaller image width, adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space between the image and the text */
}

.section5-background {
  background-position: top; /* Align the top of the image with the top of the container */
  background-repeat: no-repeat; /* Stop the background from repeating */
  background-size: cover; /* Ensures the background covers the entire element */
  background-color: white;
}

.concluding-text {
  color: #000000; /* Example paragraph text color */
  font-size: 2.75em;

  margin-top: 5vh;

  max-width: 80%; /* Gives more width to the paragraph */

  text-align: center;
  font-family: "CCAskForMercy";

  background-color: #f7f2d7; /* Pale yellow */
  border: 2px solid #c5a365; /* Added a solid border */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  border-radius: 10px;
  padding: 20px; /* Add padding around the text */
}

.Mother-Review {
  position: absolute;
  top: 5%;
  left: 1%;
  

  text-align: center;
  font-size: 1.60em;
  font-family: "CCAskForMercy";

  padding: 20px;
  border-radius: 10px;
}

.Kim-Review {
  position: absolute;
  top: 5%;
  right: 4%;

  font-size: 1.65em;
  font-family: "CCAskForMercy";

  text-align: center; /* Center the text within the div */
}

.reddit-review {
  position: absolute;
  top: 45%;
  right: 3%;

  font-size: 1.75em;
  font-family: "CCAskForMercy";

  text-align: center; /* Center the text within the div */

}

.Trump-review {
  position: absolute;
  top: 45%;
  left: 5%;
  color: #000000; /* McDonald's red */

  font-size: 1.75em;
  font-family: "CCAskForMercy";

  text-align: center; /* Center the text within the div */
}


@media only screen and (max-width: 480px) {
  .section5-image {
    max-width: 75%; /* Increase image width for smaller screens */
    margin-bottom: 0vh; /* Adjust margin as needed */
  }

  .concluding-text {
    font-size: 1.25em; /* Adjust font size for readability on smaller screens */
    padding: 15px; /* Adjust padding */
    margin-top: 3vh;
  }

  .Mother-Review,
  .Kim-Review,
  .reddit-review,
  .Trump-review {
    display: none;
  }


}


@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section5-image {
    max-width: 65%; /* Increase image width for smaller screens */
    margin-bottom: 0vh; /* Adjust margin as needed */
  }

  .concluding-text {
    font-size: 1.75em; /* Adjust font size for readability on smaller screens */
    padding: 15px; /* Adjust padding */
    margin-top: 3vh;
  }

  .Mother-Review,
  .Kim-Review,
  .reddit-review,
  .Trump-review {
    display: none;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .section5-image {
    max-width: 65%; /* Increase image width for smaller screens */
    margin-bottom: 0vh; /* Adjust margin as needed */
  }

  .concluding-text {
    font-size: 2.0em; /* Adjust font size for readability on smaller screens */
    padding: 15px; /* Adjust padding */
    margin-top: 3vh;
  }

  .Mother-Review,
  .Kim-Review,
  .reddit-review,
  .Trump-review {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .Mother-Review {
    position: absolute;
    top: 3%;
    left: 1%;
    font-size: 1.0em;
  
    text-align: center;
  
  
    padding: 20px;
    border-radius: 10px;
  }
  
  .Kim-Review {
    position: absolute;
    top: 5%;
    right: 4%;
  
    font-size: 1.0em;
 
    text-align: center; /* Center the text within the div */
  }
  
  .reddit-review {
    position: absolute;
    top: 45%;
    right: 4%;
  
    font-size: 1.0em;
    text-align: center; /* Center the text within the div */
   
  }
  
  .Trump-review {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #000000; /* McDonald's red */
  
    font-size: 1.0em;

    text-align: center; /* Center the text within the div */
  }

  .concluding-text {
    color: #000000; /* Example paragraph text color */
    font-size: 2.5em;

    margin-top: 5vh;
  
    max-width: 80%; /* Gives more width to the paragraph */
  
    text-align: center;
    font-family: "CCAskForMercy";
  
    background-color: #f7f2d7; /* Pale yellow */
    border: 2px solid #c5a365; /* Added a solid border */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    border-radius: 10px;
    padding: 20px; /* Add padding around the text */
  }

}

@media only screen and (min-width: 1281px) and (max-width: 1800px) {
  .Mother-Review {
    position: absolute;
    top: 3%;
    left: 1%;
    font-size: 1.25em;
  
    text-align: center;

  
    padding: 20px;
    border-radius: 10px;
  }


  .Kim-Review {
    position: absolute;
    top: 5%;
    right: 4%;
  
    font-size: 1.25em;

    text-align: center; /* Center the text within the div */
  }
  
  .reddit-review {
    position: absolute;
    top: 45%;
    right: 4%;
  
    font-size: 1.25em;
    text-align: center; /* Center the text within the div */
 
  }
  
  .Trump-review {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #000000; /* McDonald's red */
  
    font-size: 1.25em;
 
    text-align: center; /* Center the text within the div */
  }

 

}

@media only screen and (min-width: 2560px) {
  .Mother-Review {
    position: absolute;
    top: 3%;
    left: 1%;
    font-size: 2.25em;
  
    text-align: center;

  
    padding: 20px;
    border-radius: 10px;
  }


  .Kim-Review {
    position: absolute;
    top: 5%;
    right: 4%;
  
    font-size: 2.25em;

    text-align: center; /* Center the text within the div */
  }
  
  .reddit-review {
    position: absolute;
    top: 45%;
    right: 4%;
  
    font-size: 2.25em;
    text-align: center; /* Center the text within the div */
 
  }
  
  .Trump-review {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #000000; /* McDonald's red */
  
    font-size: 2.25em;
 
    text-align: center; /* Center the text within the div */
  }

  .concluding-text {
    font-size: 3.25em;


  }
}
