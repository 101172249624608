.section6-container h1 {
  margin-bottom: 5vh; /* Adds space below the title */
  font-size: 2em; /* Adjust size as needed */
  text-align: center;
}

/* Existing styles */
.section6-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;
  font-family: "KoHo", sans-serif;
  position: relative;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between icons */
}

.icon {
  font-size: 48px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: #000 2px solid;
  padding: 10px;
  color: #000;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.towelie {
  position: absolute;
  bottom: -50%;
  left: 2%;
  width: 5%;
  height: auto;

}
.icon:hover {
  transform: scale(1.1);


  animation: intenseStaticIconEffect 0.5s infinite;
}

/* Keyframes for icons hover effect */
@keyframes intenseStaticIconEffect {
  0%, 100% {
    transform: translate(0, 0) scale(1); /* Reset position and maintain scale */
    filter: drop-shadow(0 0 0 transparent);
  }
  10% {
    transform: translate(-1px, 1px) scale(1.1);
    filter: drop-shadow(-2px 2px 2px red);
  }
  20% {
    transform: translate(1px, -1px) scale(1.1);
    filter: drop-shadow(2px -2px 2px blue);
  }
  30% {
    transform: translate(-1px, -1px) scale(1.1);
    filter: drop-shadow(-2px -2px 2px green);
  }
  40% {
    transform: translate(1px, 1px) scale(1.1);
    filter: drop-shadow(2px 2px 2px yellow);
  }
  50% {
    transform: translate(0px, 1px) scale(1.1);
    filter: drop-shadow(0 2px 2px orange);
  }
  60% {
    transform: translate(-1px, 0px) scale(1.1);
    filter: drop-shadow(-2px 0 2px pink);
  }
  70% {
    transform: translate(1px, 1px) scale(1.1);
    filter: drop-shadow(2px 2px 2px purple);
  }
  80% {
    transform: translate(-1px, -1px) scale(1.1);
    filter: drop-shadow(-2px -2px 2px cyan);
  }
  90% {
    transform: translate(1px, 0px) scale(1.1);
    filter: drop-shadow(2px 0 2px lime);
  }
}


.divider {
  height: 100px;
  width: 2px;
  background-color: #000;
}

.text-content p {
  margin: 0;
  padding: 0 20px; /* Adjust padding as needed */
}

/* Adjustments for responsive design */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .divider {
    width: 80%; /* Adjust based on your design */
    height: 2px;
    margin: 20px 0; /* Space around divider in vertical layout */
  }
}

.newsletter-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.newsletter-content p {
  margin: 0 0 15px; /* Slightly reduced bottom margin */
  font-size: 1.3em; /* Reduced font size for the paragraph */
  font-weight: 600;
}

.newsletter-content form {
  display: flex;
  gap: 15px; /* Slightly reduced space between input and button */
}

.newsletter-content input[type="email"], .newsletter-content button {
  padding: 18px; /* Slightly reduced padding for a less bulky appearance */
  font-size: 1.1em; /* Slightly reduced font size */
}

.newsletter-content input[type="email"] {
  border: 2px solid #ddd; /* Maintaining your existing border style */
  border-radius: 5px; /* Keeping the rounded corners as per your style */
}

/* Base state for the button */
.newsletter-content button {
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
  /* Set up for the text-shadow effect */
  position: relative;
  text-shadow: none;
}

/* Hover state with static effect and distorted text */
.newsletter-content button:hover {
  background-color: #000000;
  /* Intensify the animation effect */
  animation: intenseStaticTextEffect 0.2s infinite; /* Faster animation for more intensity */
}

.nodoubt-poster {
  position: absolute;
  bottom: -50%;
  right: 5%;
  width: 12%;
  height: auto;
}

/* Keyframes for the more intense text distortion effect */
@keyframes intenseStaticTextEffect {
  0%, 100% {
    text-shadow: none;
  }
  10% {
    text-shadow: -2px 0 red;
  }
  20% {
    text-shadow: 2px 0 blue;
  }
  30% {
    text-shadow: -2px 0 green;
  }
  40% {
    text-shadow: 2px 0 yellow;
  }
  50% {
    text-shadow: -2px 0 orange;
  }
  60% {
    text-shadow: 2px 0 pink;
  }
  70% {
    text-shadow: -2px 0 purple;
  }
  80% {
    text-shadow: 2px 0 cyan;
  }
  90% {
    text-shadow: -2px 0 lime;
  }
}

@media only screen and (max-width: 768px) {
  .section6-container h1 {
    font-size: 1.8em; /* Slightly smaller title */
  }

  .icon {
    font-size: 36px; /* Smaller icons */
    width: 50px;
    height: 50px;
    padding: 8px;
  }

  .text-content p {
    padding: 0 10px; /* Reduce padding for tighter text */
    font-size: 0.9em; /* Smaller paragraph text */
  }

  .newsletter-content p {
    font-size: 1.1em; /* Smaller paragraph text in newsletter content */
  }

  .newsletter-content input[type="email"], .newsletter-content button {
    padding: 15px; /* Reduce padding */
    font-size: 1em; /* Slightly smaller font size */
  }
}


@media only screen and (max-width: 480px) {
  .section6-container h1 {
    font-size: 1.5em; /* Further reduce title size for small screens */
    margin-bottom: 3vh; /* Less space below the title */
  }

  .section6-container {

    margin-bottom: 10vh;

  }

  .icon {
    font-size: 30px; /* Even smaller icons */
    width: 40px;
    height: 40px;
    padding: 5px;
  }

  .social-media {
    gap: 10px; /* Reduce gap between icons for tighter grouping */
  }

  .text-content p {
    padding: 0 5px; /* Minimal padding to maximize space */
    font-size: 0.8em; /* Reduce text size for better fit */
  }

  .newsletter-content p {
    font-size: 1em; /* Ensure text remains legible but not too large */
    margin: 0 0 20px; /* Optimize spacing */
  }

  .newsletter-content form {
    gap: 10px; /* Tighter form elements */
  }

  .newsletter-content input[type="email"], .newsletter-content button {
    padding: 12px; /* Smaller padding for a less bulky look */
    font-size: 0.9em; /* Adjust font size for form elements */
  }

  .towelie {
    position: absolute;
    bottom: 100%;
    left: 2%;
    width:10%;
    height: auto;
  }

  .nodoubt-poster {
    display: none;
  }
}


@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section6-container h1 {
    font-size: 1.5em; /* Further reduce title size for small screens */
    margin-bottom: 3vh; /* Less space below the title */
  }

  .section6-container {

    margin-bottom: 10vh;

  }

  .icon {
    font-size: 30px; /* Even smaller icons */
    width: 60px;
    height: 60px;
    padding: 5px;
  }

  .social-media {
    gap: 10px; /* Reduce gap between icons for tighter grouping */
  }

  .text-content p {
    padding: 0 5px; /* Minimal padding to maximize space */
    font-size: 0.8em; /* Reduce text size for better fit */
  }

  .newsletter-content p {
    font-size: 1.5em; /* Ensure text remains legible but not too large */
    margin: 0 0 20px; /* Optimize spacing */
  }

  .newsletter-content form {
    gap: 10px; /* Tighter form elements */
  }

  .newsletter-content input[type="email"], .newsletter-content button {
    padding: 12px; /* Smaller padding for a less bulky look */
    font-size: 1.25em; /* Adjust font size for form elements */
  }

  .towelie {
    position: absolute;
    bottom: 75%;
    left: 2%;
    width: 10%;
    height: auto;
  }

  .nodoubt-poster {
    display: none;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .nodoubt-poster {
    display: none;
  }
  .towelie {
    position: absolute;
    bottom: 100%;
    left: 2%;
    width: 7%;
    height: auto;
  }
  
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {

  .nodoubt-poster {
    position: absolute;
    bottom: -50%;
    right: 2%;
    width: 10%;
    height: auto;
  }
  
}

/* Wide screens (1440p resolution and above) */
@media only screen and (min-width: 2560px) {
  .section6-container h1 {
    margin-bottom: 7vh; /* More space below the title */
    font-size: 3em; /* Increase title size */
    text-align: center;
  }

  .section6-container {
    margin-bottom: 15vh; /* Increase bottom margin */
  }

  .content-wrapper {
    gap: 40px; /* Increase gap between content items */
  }

  .social-media {
    gap: 30px; /* Increase gap between icons */
  }

  .icon {
    font-size: 60px; /* Larger icons */
    width: 80px;
    height: 80px;
    padding: 15px; /* Increase padding */
  }

  .towelie {
    position: absolute;
    bottom: -40%;
    left: 5%;
    width: 6%;
    height: auto;
  }

  .divider {
    height: 120px; /* Increase height */
    width: 3px; /* Increase width */
  }

  .text-content p {
    padding: 0 30px; /* Increase padding */
    font-size: 1.2em; /* Increase text size */
  }

  .newsletter-content p {
    font-size: 1.5em; /* Increase text size */
    margin: 0 0 25px; /* Increase bottom margin */
  }

  .newsletter-content form {
    gap: 20px; /* Increase gap between input and button */
  }

  .newsletter-content input[type="email"], .newsletter-content button {
    padding: 20px; /* Increase padding */
    font-size: 1.3em; /* Increase font size */
  }

  .newsletter-content input[type="email"] {
    border-radius: 8px; /* Slightly more rounded corners */
  }

  .newsletter-content button {
    border-radius: 8px; /* Slightly more rounded corners */
  }

 
}